<template>
    <div class="default-container m-0">
        <slot />
    </div>
</template>

<style>
@import '~/public/css/style.bundle.css';
@import '@vueform/multiselect/themes/default.css';
@import '~/public/css/main.css';
.default-container {
    width: 100%;
    height: 100%;
    background-color: #E5E7EB;
}

</style>
